
/* headings -----------------------------------------------------------------------------------------*/
.ws-text-heading-lg{
  @apply 
  text-4xl sm:text-5xl
  font-bold 
  tracking-tight  
}
.ws-text-heading-md{
  @apply 
  text-3xl sm:text-4xl
  font-bold 
  tracking-tight
}
.ws-text-heading-sm{
  @apply 
  text-2xl sm:text-3xl
  font-bold 
  tracking-tight
}
.ws-text-heading-xs{
  @apply 
  text-xl sm:text-2xl
  font-bold 
  tracking-tight
}

/* body text -----------------------------------------------------------------------------------------*/

.ws-text-body-base{
  @apply 
  text-lg md:text-base
  leading-7
  font-normal
}
.ws-text-body-lg{
  @apply 
  text-xl
}
.ws-text-body-sm{
  @apply 
  text-base md:text-base
}
.ws-text-body-sm-bold{
  @apply 
  text-base md:text-base
  font-semibold
}
.ws-text-body-base-bold{
  @apply 
  text-lg
  leading-7
  font-semibold
}

/* CTAs -----------------------------------------------------------------------------------------*/

.ws-cta-primary{
  @apply 
  px-6 py-3
  rounded-lg
  bg-primary hover:bg-primary-600
  text-lg 
  font-semibold  
  text-white 
}

.ws-cta-secondary{
  @apply 
  px-6 py-3
  rounded-lg
  bg-gray-700 hover:bg-gray-600
  text-lg 
  font-semibold  
  text-white 
}

/* Other Text -----------------------------------------------------------------------------------------*/

.ws-text-overline{
  @apply 
  text-sm md:text-sm
  font-semibold
  tracking-wide
  uppercase
}


/* layout stuff -----------------------------------------------------------------------------------------*/

.ws-text-menu-mobile{
  @apply 
  font-sans 
  text-base
  font-semibold 
  tracking-normal
  text-gray-700 hover:text-teal-600
}
.ws-header-desktop-navigation{
  @apply 
  font-sans 
  text-sm
  font-semibold 
  tracking-wide 
  text-gray-800 hover:text-teal-600
}


/* other -----------------------------------------------------------------------------------------*/

.ws-text-label{
  @apply 
  font-semibold
  text-base md:text-base
  text-gray-800 
  
}

.ws-text-input{
  @apply 
  focus:ring-transparent 
  focus:border-gray-300 
  p-2 
  rounded-md 
  shadow-sm 
  bg-white 
  font-normal
  border border-gray-200 
  text-gray-700 block mt-1 w-full
}

.ws-alert-green{
  @apply 
  bg-green-200
  text-green-800 
  font-normal
  text-base
  p-3
  rounded-sm
}



